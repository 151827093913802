import React, { useState } from "react";
import styled, { css } from "styled-components";
import Sticky from "react-stickynode";
import { HamburgerArrow } from "react-animated-burgers";

import logoWhite from '../assets/notjust_white_logo.png';

const navItems = ["Home", "Our Story", "Projects", "Contact"];

const Container = styled.div`
  display: flex;

  padding: 0 10px 0 0;
  background-color: ${({ theme }) => theme.colors.orangeAmerican};
  z-index: ${({ theme }) => theme.zIndex.topNav};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  flex: 1;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    margin-left: -20px;
  }
`;

const Desktop = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: flex;
  &.animate {
    left: -100%;
    transform: translateX(100%);
    transition: left 1s, transform 1s;
  }

  &.animate.grow {
    left: 0%;
    transform: translateX(0%);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
  position: relative;
  left: 0px;
  margin-top: -2px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    &.animate {
      left: -50px;
      transform: translateX(100%);
      transition: left 1s, transform 1s;
    }
    &.animate.grow {
      left: -5px;
      transform: translateX(0%);
    }
  }
`;

const NavItemsWrapper = styled.nav`
  width: fit-content;
  margin-right: ${({ theme }) => theme.spacing.large}px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width:100%;
    margin-right: 0px;
  }
`;

const NavItemsList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    min-width: 240px;
  }
`;

const NavItem = styled.li``;
const NavLink = styled.a`
  padding: 0px 12px;
  line-height: 50px;
  font-size: 25px;
  font-weight: 700;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    line-height: 20px;
    font-size: 0.9em;
    margin-right: 4px;
    padding: 0px;
    line-height: 20px;
  }
`;

const Char = styled.span`
  transition: all 0.1s;
  cursor: pointer;
  display: inline-block;

  &:hover {
    transform: scale(1.4);
  }
`;

const buttonStyle = css`

`;

const StyledHamburgerArrow = styled(HamburgerArrow)`
@media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
  padding-left: 5px;
}
`;

export function Nav({ className, navigateTo = () => {} }) {
  const [isHamburgerMenuActive, setHamburgerMenuActive] = useState(false);

  const toggleHamburgerMenuButton = () => {
    setHamburgerMenuActive(!isHamburgerMenuActive);
  };

  const renderCharsFromWord = (word) => {
    const arrayChars = [...word];
    return arrayChars.map((char, i) => <Char key={char + i}>{char}</Char>);
  };

  const renderNavItems = () => {
    return navItems.map((navItem, i) => (
      <NavItem key={navItem.trim()} onClick={() => navigateTo(navItem)}>
        <NavLink>{renderCharsFromWord(navItem)}</NavLink>
      </NavItem>
    ));
  };

  return (
    <Sticky enabled={true} className={className} innerZ="11">
      <Container>
      <Wrapper>
        <Logo src={logoWhite} className={isHamburgerMenuActive ? "animate grow" : "animate"} />
        <NavItemsWrapper>
          <NavItemsList>
            <Desktop>{renderNavItems()}</Desktop>
            <Mobile
              className={isHamburgerMenuActive ? "animate grow" : "animate"}
            >
              {isHamburgerMenuActive ? renderNavItems() : null}
            </Mobile>
          </NavItemsList>
        </NavItemsWrapper>
        <Mobile>
          <StyledHamburgerArrow
            isActive={isHamburgerMenuActive}
            toggleButton={toggleHamburgerMenuButton}
            buttonWidth={25}
          />
        </Mobile>
        </Wrapper>
      </Container>
    </Sticky>
  );
}
