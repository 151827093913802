import { css } from 'styled-components';

const featureColors = {
  promoPrice: '#3329c7',
};

const colors = {
  ...featureColors,
  black: '#000000',
  black90: 'rgba(0, 0, 0, 0.9)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black65: 'rgba(0, 0, 0, 0.65)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black10: 'rgba(0, 0, 0, 0.1)',
  lightBlack: '#404040',
  darkLightBlack: '#1F1F1F',

  orangeLight: '#FF9900',
  orangeElectric: '#FF3403',
  orangeGiants: '#FF581B',
  orangeAmerican: '#FE8B02',
  orangeRouge1: '#fb6018',


  darkGrey: '#707070',
  darkGrey40: 'rgba(112, 112, 112, 0.4)',
  darkGrey20: 'rgba(112, 112, 112, 0.2)',
  dimGrey: '#7d7d7d',
  mediumGrey: '#d3d3d3',
  lightGrey: '#f5f5f5',
  extraLightGrey: '#f8f8fc',
  superLightGrey: '#fcfcfd',
  gray: '#808080',

  white: '#ffffff',
  whiteSmoke: '#f8f8f8',
  white80: 'rgba(255, 255, 255, 0.8)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white24: 'rgba(255, 255, 255, 0.24)',

  paleSlate: '#c2c4c8',
  offWhite: '#f5efef',
  nude: '#f5f0ef',
  nude80: 'rgba(245, 240, 239, 0.8)',
  nude90: 'rgba(245, 240, 239, 0.9)',
  blush: '#f5ded7',
  darkBlush: '#cb4848',
  roseGold: '#d9ada6',
  pink: '#e35d6d',
  red: '#d0021b',
  redError: 'rgb(227, 93, 109)',
  scarlet: '#dc2e42',
  darkRed: '#dc3246',
  blue: '#3329C7',
  gray98: '#fafafa',
  gray58: '#949494',
  gray20: '#333333',

  coral: '#e51f26',
  coral03: 'rgba(229, 31, 38, 0.03)',

  electricViolet: '#3424CC',
  electricViolet03: 'rgba(52, 36, 255, 0.03)',
  electricPurple: '#7c89d3',
  electricPurple12: 'rgba(124, 137, 211, 0.12)',
  lightPurple: '#c56eff',
  redViolet: '#c72966',
  redViolet12: 'rgba(199, 41, 102, 0.12)',
  bluePurple: '#b4a3f3',

  darkLavender: '#5161bd',
  boneLavender: '#fafafd',
  lavender: '#bdbcdb',
  lavender80: 'rgba(189, 188, 219, 0.8)',
  lavender60: 'rgba(189, 188, 219, 0.6)',
  lavender40: 'rgba(189, 188, 219, 0.4)',
  lavender20: 'rgba(189, 188, 219, 0.2)',
  lavender14: 'rgba(189, 188, 219, 0.14)',
  lightLavender: '#e5e4f4',
  lightLavender52: 'rgba(228, 228, 244, 0.52)',
  extraLightLavender: 'rgba(229, 228, 244, 0.3)',
  mediumLavender: '#7f8bc3',

  lavenderGradient: 'linear-gradient(90deg, #425fa1 0%, #b4479a 100%)',
};

const getButtonBackgroundColor = props => {
  if (props.secondary) {
    return 'transparent';
  }
  if (props.alternate) {
    return props.theme.colors.bluePurple;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.black;
};

const getButtonFontColor = props => {
  if (props.secondary) {
    return props.white ? props.theme.colors.white : props.theme.colors.black;
  }
  return props.white ? props.theme.colors.black : props.theme.colors.white;
};

const getButtonBorderColor = props => {
  if (props.alternate) {
    return props.theme.colors.bluePurple;
  }
  if (props.secondary && props.white) {
    return props.theme.colors.white;
  }
  return props.white ? props.theme.colors.white : props.theme.colors.black;
};

const defaultDisabledButtonStyle = css`
  background-color: ${colors.mediumGrey};
  border-color: ${colors.mediumGrey};
  color: ${colors.dimGrey};
  cursor: not-allowed;
`;

export default {
  colors,
  fonts: {
    button: '"Work Sans", Sans-serif',
    title: '"Work Sans", Sans-serif',
    description: '"PT Sans", sans-serif',
  },
  focusColor: colors.darkLavender,
  breakpoints: {
    small: 0,
    medium: 414,
    mobile: { maxWidth: 767 },
    large: 768,
    xlarge: 1024,
    xxlarge: 1440,
  },
  spacing: {
    tiny: 8,
    verySmall: 12,
    small: 16,
    moderate: 24,
    medium: 32,
    large: 40,
    xlarge: 80,
  },
  // A place to store flags for helping with contextual styling. For example,
  // you could set a `cartDrawer` flag so that components can render differently
  // if they know they are inside the CartDrawer component, without needing to
  // use CSS selectors.
  context: {},
  desktopBreakpoint: 'large',
  zIndex: {
    decrement: -10,
    base: 0,
    basic: 1,
    signInModalClose: 2,
    navItems: 3,
    sideNav: 10,
    topNav: 11,
    toast: 12,
    flyout: 100,
    errorOverlay: 21,
  },
  nav: {
    desktopHeight: 107,
    tabletHeight: 60,
    mobileHeight: 50, // height for new top logo nav
    mobileSubNav: 40, // height for new added sub nav for mobile
  },
};
