import React from "react";
import styled from 'styled-components';
import { FaLongArrowAltRight } from 'react-icons/fa';

const Container = styled.div`
  font-family: ${({ theme }) => theme.fonts.button};
`;
const ButtonWrapper = styled.div``;

const ButtonLink = styled.a`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #23362B;
  border-radius: 0px 0px 0px 0px;
  padding: ${({ theme }) => theme.spacing.verySmall}px ${({ theme }) => theme.spacing.moderate}px;

  &:hover {
    transition: all .3s;
    background-color: ${({ theme }) => theme.colors.orangeAmerican};
  }
`;

const ButtonContent = styled.span``;

const Label = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.8vw;
  font-weight: 700;
  vertical-align: middle;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 18px;
    line-height: 18px;
  }
`;
const StyledFaLongArrowAltRight = styled(FaLongArrowAltRight)`
  font-weight: 900;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 15px;
`;


export function CtaButton({className, labelText = 'click here'}) {
  return (
      <Container className={className}>
        <ButtonWrapper>
          <ButtonLink href="#" role="button">
            <ButtonContent>
              <Label>{labelText}</Label>
              <StyledFaLongArrowAltRight/>
            </ButtonContent>
          </ButtonLink>
        </ButtonWrapper>
      </Container>
  );
}

CtaButton.ButtonWrapper = ButtonWrapper;
