import React from "react";
import styled from 'styled-components';
import { CtaButton } from './CtaButton';
import { Divider } from './Divider';
import { FramedImage } from './FramedImage';
import { UnderlinedTitle } from './UnderlinedTitle';

import notjust_img1 from '../assets/notjust_img1.jpg';
import notjust_img2 from '../assets/notjust_img2.jpg';
import pattern5 from '../assets/pattern5.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 75px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    padding: 0px;
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  margin-bottom: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    flex-direction: column;
  }
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 5%;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
  }
`;

const FramesWrapper = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    flex-direction: column;
  }
`;
const FramesColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5% 5% 5% 5%;
  gap: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    padding-top: 10%;
  }
`;

const TopRoundedFramedImage = styled(FramedImage)`
  ${FramedImage.ImageWrapper}, ${FramedImage.Image} {
    border-radius: 0px 45px 0px 0px;
  }
`;

const PatternContainer = styled.div`
  padding: 5px 5px 5px 5px;
  background-image: url(${pattern5});
  background-position: center center;
  border-radius: 0px 0px 0px 55px;
`;

const BottomRoundedFramedImage = styled(FramedImage)`
  ${FramedImage.ImageWrapper} {
    border-radius: 0px 0px 0px 55px;
    border-style: none;
  }
  ${FramedImage.Image} {
    opacity: 0;
  }
`;

const CtasWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    max-width: 450px;
  }
`;

const StyledCtaButton = styled(CtaButton)`
  ${CtaButton.ButtonWrapper} {
    padding: ${({ theme }) => theme.spacing.moderate}px 0px;
  }
`;

const Desktop = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    display: none;
  }
`;

const Mobile = styled.div`
  padding-right: 5%;
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    display: none;
  }
`;

export function HomePageHeader({className}) {

  const renderDottedDividers = () => {
    return <Desktop>{[...Array(8)].map( (el, i) => (<Divider type="dotted" size={60 - i*5} key={i}/>) )}</Desktop>;
  }

  const renderMobileDottedDividers = () => {
    return <Mobile>{[...Array(5)].map( (el, i) => (<Divider type="dotted" size={60 - i*5} key={i}/>) )}</Mobile>;
  }

  const renderSolidDividers = () => {
    return [...Array(8)].map( (el, i) => (<Divider type="solid" size={0} key={i}/>) );
  }

  return (
    <Container className="className">
      <Wrapper>
        <LeftSection>
            {renderDottedDividers()}
            {renderMobileDottedDividers()}
            <UnderlinedTitle labelText="not just what you think"/>
          <CtasWrapper>
            <StyledCtaButton labelText="Click Here"/>
            <StyledCtaButton labelText="Read More"/>
          </CtasWrapper>
        </LeftSection>
        <FramesWrapper>
          <FramesColumn>
            <FramedImage
              src={notjust_img1}
              hasPadding
            />
            <PatternContainer>
              <BottomRoundedFramedImage
                src={notjust_img2}
                hasPadding
              />
            </PatternContainer>
          </FramesColumn>
          <FramesColumn>
            <TopRoundedFramedImage
              src={notjust_img2}
              hasPadding
            />
            <FramedImage
              src={notjust_img1}
              hasPadding
            />
          </FramesColumn>
        </FramesWrapper>
      </Wrapper>
      {renderSolidDividers()}
    </Container>
  );
}
