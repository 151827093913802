import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { HomePageHeader } from "./components/HomePageHeader";
import { ImageParallax } from "./components/ImageParallax";
import { InterviewSection } from "./components/InterviewSection";
import { Nav } from "./components/Nav";
import { SocialMediaSection } from "./components/SocialMediaSection";
import { SocialSection } from "./components/SocialSection";
import { StorySection } from "./components/StorySection";

const Container = styled.div``;
const LeftSide = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.sideNav};
  background-color: ${({ theme }) => theme.colors.orangeAmerican};

  ${({ shouldHighlight }) =>
    shouldHighlight &&
    css`
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    -webkit-animation: ${blinkRed} 1s 2;
    -moz-animation: ${blinkRed} 1s 2;
    -ms-animation: ${blinkRed} 1s 2;
    -o-animation: ${blinkRed} 1s 2;
    animation: ${blinkRed} 1s 2;
    `};
`;

const blinkRed = keyframes`
    from { background-color: ${({ theme }) => theme.colors.orangeAmerican}; }
    50% { background-color: #b36201; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #b36201 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
    to { background-color: ${({ theme }) => theme.colors.orangeAmerican}; }
`;

function App() {
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const storyRef = useRef(null);
  const projectsRef = useRef(null);

  useEffect(() => {
    if(shouldHighlight) {
      const timer = setTimeout(() => {
        setShouldHighlight(false)
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [shouldHighlight]);

  const navigateTo = (ref) => {
    switch (ref) {
      case "Our Story":
        window.scrollTo({
          top: storyRef.current.offsetTop,
          left: 0,
          behavior: "smooth",
        });
        break;
      case "Projects":
        window.scrollTo({
           top: projectsRef.current.offsetTop,
          left: 0,
          behavior: "smooth",
        });
        break;
      case "Contact":
        setShouldHighlight(true);
        break;
      default:
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
  };

  return (
    <>
      <Nav navigateTo={navigateTo} />
      <Container>
        <HomePageHeader />
        <StorySection ref={storyRef} />
        <ImageParallax />
        <InterviewSection ref={projectsRef} />
        <SocialMediaSection
          instagramLink="https://www.instagram.com/gwenrdf/"
          email="mailto:name@email.com"
          linkedinLink="https://www.linkedin.com/in/gwendoline-roger-perez-72807931/"
        />
      </Container>
      <LeftSide shouldHighlight={shouldHighlight}>
        <SocialSection/>
      </LeftSide>
    </>
  );
}

export default App;
