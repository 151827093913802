import React from "react";
import styled from "styled-components";
import { CtaButton } from "./CtaButton";
import { Divider } from "./Divider";

import interview1BG from '../assets/interview1.jpg';

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  flex-grow: 1;
  width: 33%;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
    order: 2;
  }
`;
const Image = styled.div`
  background-image: url(${interview1BG});  
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    height: 150px;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  width: 33%;
  margin: 0% 0% 0% 0%;
  padding: 10px 0px 0px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-width: 0px 3px 0px 3px;
  border-color: #111111;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
    order: 1;
  }
`;
const Name = styled.label`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.title};
  color: #111111;
  font-size: 4vw;
  font-weight: 900;
  line-height: 1;
  text-transform: uppercase;
  margin: 0% 0% 0% 0%;
  padding: 5px 30px 5px 30px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 33%;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
    height: 150px;
    order: 3;
    margin: 0 0 1.5em;
  }
`;
const Description = styled.p`
  color: #333333;
  font-family: ${({ theme }) => theme.fonts.description};
  font-size: 1.8vw;
  font-weight: 700;
  padding: 2% 8% 2% 8%;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 20px;
    padding: 20px 20px 20px 20px;
  }
`;

const StyledCtaButton = styled(CtaButton)`
  padding: 0 8% 0 0;
`;

export const InterviewSection = React.forwardRef(({ className, img, name, description }, ref) => {
  const interview1Src = require(`../assets/interview1.jpg`).default;

  const renderSolidDividers = () => {
    return [...Array(5)].map((el, i) => (
      <Divider type="solid" size={0} key={i} />
    ));
  };

  return (
    <Container className={className} ref={ref}>
      <ImageWrapper>
        <Image bgSrc={interview1Src}/>
      </ImageWrapper>
      <NameWrapper>
        {renderSolidDividers()}
        <Name>Interview Name</Name>
        {renderSolidDividers()}
      </NameWrapper>
      <DescriptionWrapper>
        <Description>
          Interview COMING SOON...
        </Description>
        <StyledCtaButton labelText="Watch" />
      </DescriptionWrapper>
    </Container>
  );
});
