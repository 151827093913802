import React from "react";
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

const Container = styled.section`
  background-color: #FFFFFF;
  /*background-image: url(https://wpkoi.com/wpkoi-elementor-templates-demo/wp-content/uploads/2021/08/omkara-6-1.jpg);
  */
  background-image: url(${({ src }) => src});
  background-position: bottom center;
  background-size: cover;
  border-style: solid;
  border-width: 0px 0px 3px 0px;
  border-color: #111111;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px;
  padding: 0px 0px 0px 0px;
`;

const OverlayLayer = styled.div`
  background-color: transparent;
  background-image: radial-gradient(at center center, rgba(255, 255, 255, 0) 0%, #111111 50%);
  opacity: 0.2;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 12% 0;
`;

const LetterWrapper = styled.div`
  text-align: center;
  width: 11%;
  max-width: 11%;
  transform: ${({transform}) => transform};
`;
const Letter = styled.h2`
  color: ${({ theme }) => theme.colors.orangeAmerican};
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 15vw;
  font-weight: 900;
  line-height: 1.2em;
  text-transform: uppercase;
  opacity: 0.5;
`;

export function ImageParallax({className}) {
  const mainImgSrc = require(`../assets/notjust_img2.jpg`).default;
  return (
    <Container className="className" src={mainImgSrc}>
      <OverlayLayer/>
      <Wrapper>
        <Parallax translateY={["-150px", "150px"]} transform="translate3d(0px, -150px, 0px)"><LetterWrapper><Letter>N</Letter></LetterWrapper></Parallax>
        <Parallax translateX={["-40px", "40px"]} transform="translate3d(-40px, 0px, 0px)"><LetterWrapper><Letter>O</Letter></LetterWrapper></Parallax>
        <Parallax rotate={[-155, 155]} transform="rotate(-155deg)"><LetterWrapper><Letter>T</Letter></LetterWrapper></Parallax>
        <Parallax translateY={["-150px", "150px"]} transform="translate3d(0px, -150px, 0px)"><LetterWrapper><Letter>J</Letter></LetterWrapper></Parallax>
        <Parallax translateX={["-90px", "90px"]} transform="translate3d(-90px, 0px, 0px)"><LetterWrapper><Letter>U</Letter></LetterWrapper></Parallax>
        <Parallax rotate={[-155, 155]} transform="rotate(-155deg)"><LetterWrapper><Letter>S</Letter></LetterWrapper></Parallax>
        <Parallax translateY={["-150px", "150px"]} transform="translate3d(0px, -150px, 0px)" speed={30}><LetterWrapper><Letter>T</Letter></LetterWrapper></Parallax>
      </Wrapper>
    </Container>
  );
}
