import React from "react";
import styled from "styled-components";
import { FaInstagram, FaLinkedinIn, FaRegEnvelope } from "react-icons/fa";

const Container = styled.section`
  width: 100%;
  margin-bottom: 20px;
`;

const SectionWrapper = styled.div`
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    flex-flow: row wrap;
  }
`;

const TitleSection = styled.section`
  width: 25%;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  background-image: url(${({patternSrc}) => patternSrc});
  background-position: center center;
  min-height: 1px;
`;

const TitleLabelWrapper = styled.div`
  padding: 3% 3% 3% 2%;
  display: flex;
  justify-content: center;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.xlarge - 1}px) {
    padding: 7% 3% 7% 2%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    justify-content: start;
  }
`;

const TitleLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 45px;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0% 0% 0% 0%;
  padding: 5px 30px 5px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #111111;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.xlarge - 1}px) {
    font-size: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.large - 1}px) {
    font-size: 25px;
  }
`;

const FollowSection = styled.section`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px 0px 3px 3px;
  border-color: #111111;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
    border-width: 0px;
  }
`;

const FollowTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.description};
`;

const LinkSection = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px 0px 3px 0px;
  border-color: #111111;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    border-width: 0px 0px 3px 0px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const IconLink = styled.a`
  text-decoration: none;
  color: #111111;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledFaRegEnvelope = styled(FaRegEnvelope)`
  font-size: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 35px;
  }
`;

const StyledFaInstagram = styled(FaInstagram)`
  font-size: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 35px;
  }
`;

const StyledFaLinkedinIn = styled(FaLinkedinIn)`
  font-size: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 35px;
  }
`;

export function SocialMediaSection({
  className,
  instagramLink,
  email,
  linkedinLink,
}) {
  const patternSrc = require(`../assets/pattern5.png`).default;
  return (
    <Container className={className}>
      <SectionWrapper>
        <TitleSection>
          <TitleWrapper patternSrc={patternSrc}>
            <TitleLabelWrapper>
              <TitleLabel>Socials</TitleLabel>
            </TitleLabelWrapper>
          </TitleWrapper>
        </TitleSection>
        <FollowSection>
          <FollowTitle>Follow her</FollowTitle>
        </FollowSection>
        <LinkSection>
          {email ? (
            <LinkWrapper>
              <IconLink href="#" target="_blank">
                <StyledFaRegEnvelope/>
              </IconLink>
            </LinkWrapper>
          ) : null}
          {instagramLink ? (
            <LinkWrapper>
              <IconLink href="#" target="_blank">
                <StyledFaInstagram/>
              </IconLink>
            </LinkWrapper>
          ) : null}
          {linkedinLink ? (
            <LinkWrapper>
              <IconLink href="#" target="_blank">
                <StyledFaLinkedinIn/>
              </IconLink>
            </LinkWrapper>
          ) : null}
        </LinkSection>
      </SectionWrapper>
    </Container>
  );
}
