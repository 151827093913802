import React from "react";
import styled from 'styled-components';
import { FaInstagram, FaLinkedinIn, FaRegEnvelope, FaYoutube } from "react-icons/fa";

const Container = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  margin: 250px 0px 0px 0px;
  line-height: 1;
`;

const ListIcons = styled.ul`
  padding: 15px 4px;
  margin: 0;
  list-style: none;
  display: inline-block;
  text-align: center;
`;

const IconWrapper = styled.li`
  line-height: 2;
`;

const IconLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.orangeGiants};
  }
`;

export function SocialSection({className}) {
  return (
    <Container>
      <ListIcons>
        <IconWrapper>
          <IconLink href="#" target="_blank">
            <FaInstagram size="20"/>
          </IconLink>
        </IconWrapper>
        <IconWrapper>
          <IconLink href="#" target="_blank">
            <FaYoutube size="20"/>
          </IconLink>
        </IconWrapper>
        <IconWrapper>
          <IconLink href="#" target="_blank">
            <FaLinkedinIn size="20"/>
          </IconLink>
        </IconWrapper>
        <IconWrapper>
          <IconLink href="#" target="_blank">
            <FaRegEnvelope size="20"/>
          </IconLink>
        </IconWrapper>
      </ListIcons>
    </Container>
  );
}
