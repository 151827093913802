import React from "react";
import styled from 'styled-components';

const Container = styled.div``;
const ImageWrapper = styled.div`
  padding: ${({hasPadding}) => (
      hasPadding ? '5px' : '0px')};
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #111111;
  border-radius: 0px 0px 0px 0px;
`;
const Image = styled.img`
  height: auto;
  max-width: 100%;
`;

FramedImage.ImageWrapper = ImageWrapper;
FramedImage.Image = Image;

export function FramedImage({className, src, title = '', altText = '', hasPadding = false}) {
  return (
      <Container className={className}>
        <ImageWrapper hasPadding={hasPadding}>
          <Image src={src} title={title} alt={altText}/>
        </ImageWrapper>
      </Container>
  );
}
