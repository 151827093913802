import React from "react";
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const DividerWrapper = styled.div`
  margin: 0px 0px -10px 0px;
  padding: 0% 0% 0% ${({size}) => size}%;
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
`;

const DividerRow = styled.div`
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const Separator = styled.span`
  width: 100%;
  display: flex;
  margin: 0;
  direction: ltr;
  border-top: 8px ${({type}) => type} #111111;
`;

export function Divider({className, size, type}) {
  return (
      <Container className={className}>
        <DividerWrapper size={size}>
          <DividerRow>
            <Separator type={type}/>
          </DividerRow>
        </DividerWrapper>
      </Container>
  );
}
