import React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: ${({ theme }) => theme.fonts.title};
`;
const TitleWrapper = styled.div`
  margin: 10% 15% 0% 0%;
  padding: 0px 0px 0px 0px;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    #ffffff 75%,
    ${({ theme }) => theme.colors.orangeAmerican} 70%
  );
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    margin-top: 7%;
    background-image: linear-gradient(
      180deg,
      #ffffff 65%,
      ${({ theme }) => theme.colors.orangeAmerican} 70%
    );
  }
`;

const Label = styled.h2`
  font-size: 5.5vw;
  font-weight: 900;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 30px;
    line-height: 30px;
    max-width: 50%;
  }
`;

export function UnderlinedTitle({ className, labelText = "title" }) {
  return (
    <Container className={className}>
      <TitleWrapper>
        <Label>{labelText}</Label>
      </TitleWrapper>
    </Container>
  );
}
