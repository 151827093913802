import React from "react";
import styled from "styled-components";
import { CtaButton } from "./CtaButton";
import { FramedImage } from "./FramedImage";
import { UnderlinedTitle } from "./UnderlinedTitle";

import fem_masc from "../assets/fem_masc.jpeg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 75px;
  margin-top: 45px;
  border-style: solid;
  border-width: 3px 0px 0px 0px;
  border-color: ${({ theme }) => theme.colors.black};
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    padding: 0px;
    margin-top: 0px;
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    flex-direction: column;
  }
`;
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10% 10% 10% 0px;
  border-style: solid;
  border-width: 0px 3px 0px 0px;
  border-color: #111111;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    padding: 20px;
    border-width: 0px;
  }
`;

const DescriptionWrapper = styled.div`
  color: #333333;
  font-family: ${({ theme }) => theme.fonts.description};
  font-size: 1.8vw;
  font-weight: 700;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    font-size: 3.8vw;
  }
`;

const Description = styled.p`
  margin: 0 0 1.5em;
  padding: 0;
  line-height: 1.5em;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: ${(props) => props.theme.breakpoints.large - 1}px) {
    width: 100%;
  }
`;
const FrameSection = styled.section`
  display: flex;
  flex: 1;
  padding: 20px 20px 20px 20px;
`;

const SectionWelcome = styled.section`
  display: flex;
  width: 100%;
  border-style: solid;
  border-width: 3px 0px 3px 0px;
  border-color: #111111;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
`;

const WelcomeWrapper = styled.div`
  width: 100%;
  background-image: url(${({patternSrc}) => patternSrc});
  background-position: center center;
  min-height: 1px;
`;

const WelcomeLabelWrapper = styled.div`
  padding: 12% 0% 12% 0%;
  display: flex;
  justify-content: center;
`;

const WelcomeLabel = styled.label`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 5.5vw;
  font-weight: 900;
  margin: 0% 0% 0% 0%;
  padding: 5px 30px 5px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #111111;
`;

const CtasWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const StorySection = React.forwardRef(({ className }, ref) => {
  const patternSrc = require(`../assets/pattern5.png`).default;
  return (
    <Container className="className" ref={ref}>
      <Wrapper>
        <LeftSection>
          <UnderlinedTitle labelText="Our Story" />
          <DescriptionWrapper>
            <Description>
              In a world where choices define us, "Not Just" stands as a beacon of empowerment for women who dare to dream beyond the conventional. 
              It's a brand that celebrates the multifaceted nature of women, encouraging them to embrace their diverse passions and talents. 
              "Not Just" is more than a statement; it's a movement, a declaration that women are not just one thing – 
              they are complex, capable, and unstoppable
            </Description>
          </DescriptionWrapper>
          <CtasWrapper>
            <CtaButton labelText="Click Here" />
          </CtasWrapper>
        </LeftSection>
        <RightSection>
          <FrameSection>
            <FramedImage
              src={fem_masc}
              hasPadding
            />
          </FrameSection>
          <SectionWelcome>
            <WelcomeWrapper patternSrc={patternSrc}>
              <WelcomeLabelWrapper>
                <WelcomeLabel>Welcome</WelcomeLabel>
              </WelcomeLabelWrapper>
            </WelcomeWrapper>
          </SectionWelcome>
        </RightSection>
      </Wrapper>
    </Container>
  );
});
